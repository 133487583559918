import * as React from "react";

import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { green } from "@mui/material/colors";

import { DashboardLineItem } from "../DashboardLineItem/DashboardLineItem";

import { useHistory, useParams } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

interface IIAQDeviceProps {}

export const IAQDevice: React.FC<IIAQDeviceProps> = (props) => {
	const history = useHistory();
	const params: any = useParams();
	const { clientId, buildingId } = params;

	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);

	// Route Data
	const selectedBuilding = buildings[buildingId];

	const floors = selectedBuilding?.floors;

	return (
		<DashboardLineItem title="Floors" button={"Floor IAQ"}>
			<List
				sx={{ width: "100%", bgcolor: "background.paper" }}
				component="nav"
				aria-labelledby="nested-list-subheader"
				disablePadding
			>
				{floors?.map((floor: any) => (
					<List key={floor.id} disablePadding sx={{ width: "100%" }}>
						<ListItem
							secondaryAction={
								<div style={{ color: green[500], fontWeight: 700 }}>
									{`${floor?.metadata?.airQuality?.avg || "N/A"}%` || ""}
								</div>
							}
						>
							<ListItemButton
								disableGutters
								onClick={() =>
									history.push(
										`/client/${clientId}/building/${buildingId}/floor/${floor.id}?iaq`
									)
								}
								style={{ paddingBottom: 0 }}
							>
								<ListItemText
									key={floor.id}
									primary={floor.name}
									sx={{ fontWeight: 700 }}
								/>
							</ListItemButton>
						</ListItem>
						<ListItem dense>
							<List sx={{ width: "100%" }} disablePadding component="div">
								{floor.spaces?.map((space: any) => (
									<ListItemButton
										key={space.id}
										disableGutters
										style={{ padding: 0 }}
									>
										{/* <ListItem
											disablePadding
											disableGutters
											sx={{ pl: 4 }}
											secondaryAction={
												<div>
													{`${space?.metadata?.airQuality?.avg || "N/A"}%` ||
														""}
												</div>
											}
										>
											<ListItemText primary={space.name} />
										</ListItem> */}

										<ListItem
											disablePadding
											disableGutters
											secondaryAction={
												<div>
													{`${space?.metadata?.airQuality?.avg || "N/A"}%` ||
														""}
												</div>
											}
										>
											<ListItemText
												primary={space.name}
												sx={{
													".MuiListItemText-primary": {
														fontWeight: 800,
													},
												}}
											/>
										</ListItem>
									</ListItemButton>
								))}
							</List>
						</ListItem>
					</List>
				))}
			</List>
		</DashboardLineItem>
	);
};
