import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
	selectBuilding,
	selectSpace,
} from "../../features/appState/appStateSlice";

// Util
import { flatten, sortBy } from "lodash";
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import { resetWeather } from "../../features/weather/weatherSlice";

import { useHistory, useParams, useLocation } from "react-router";

interface IClientListProps {}

export const BuildingSelector: React.FC<IClientListProps> = (props) => {
	const history = useHistory();
	// State
	const dispatch: any = useDispatch();
	const appState: any = useAppSelector((state: RootState) => state.appState);

	// Data
	const api: any = useAppSelector((state: any) => state.firebase.profile?.api);
	const buildings: any = useAppSelector((state: RootState) => state.buildings);
	const clients: any = useAppSelector((state: RootState) => state.clients);
	const clientData: any = clients?.data[appState.selectedClient?.id];

	// Params
	const location = useLocation();

	// Data
	const buildingList: any = sortBy(
		flatten(clientData?.sites?.map((site: any) => site?.buildings)),
		["name"]
	);

	// Select building if none selected on initial load
	// Do not select if already within nested route (this is done in Pages/Space)
	React.useEffect(() => {
		if (
			appState?.selectedBuilding?.id === "" &&
			buildingList.length &&
			!location.pathname.includes("building")
		) {
			dispatch(selectBuilding(buildingList[0]));
		}
	}, [appState?.selectedClient?.id]);

	// Load building data if none
	React.useEffect(() => {
		if (
			!buildings.data[appState.selectedBuilding?.id] &&
			buildings.status !== "loading"
		) {
			// Make sure api key and building selected
			if (appState.selectedBuilding?.id) {
				dispatch(fetchBuilding({ api, id: appState.selectedBuilding?.id }));
			}
		}
	}, [appState.selectedBuilding?.id]);

	const handleChange = (building: any) => {
		// Reset weather
		dispatch(resetWeather({}));

		// Reset Space
		dispatch(
			selectSpace({
				id: "",
				name: "",
				buildingId: "",
				clientId: "",
			})
		);

		// Select Building
		dispatch(selectBuilding(building));

		// Reroute to dash if building changed
		if (location.pathname.includes("building")) {
			history.push("/dashboard");
		}
	};

	return (
		<div style={{ margin: ".5rem" }}>
			<Box sx={{ minWidth: 120 }}>
				<FormControl>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={appState.selectedBuilding?.id || ""}
						style={{ color: "white" }}
						variant="standard"
					>
						{buildingList?.map((building: any) => (
							<MenuItem
								key={building.id}
								value={building.id}
								onClick={() => handleChange(building)}
							>
								{building?.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</div>
	);
};
