import * as React from "react";

import {
	Grid,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Slider,
	Switch,
	Typography,
} from "@mui/material";
import { find } from "lodash";

import { functions } from "../../firebase/firebase";
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { fetchDevice } from "../../features/devices/devicesSlice";

const postEndpoint = functions.httpsCallable("postEndpoint");

interface IIAQProps {
	device: any;
}

// Styles
const classes = {
	list: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",
	},
};

interface ILightProps {
	device: any;
}

export const Light: React.FC<ILightProps> = ({ device }) => {
	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const devices = useAppSelector((state: any) => state.devices);

	const dispatch = useDispatch();

	const activeDevice = device.points?.length ? device : devices.data[device.id];
	const points = activeDevice?.points || [];

	// Find individual iaq data points
	const lightSwitch = find(points, ["virtualDeviceSlot", "lightSwitch"]);
	let [value, setValue] = React.useState(lightSwitch?.data?.value || 0);

	const [checked, setChecked] = React.useState(
		lightSwitch?.data?.value || false
	);

	// Load device points if not loaded

	const handleChange = (event: Event, newValue: number | number[]) => {
		setValue(newValue);
	};

	const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);

		postEndpoint({
			api,
			user: "demo",
			route: `point/${lightSwitch?.id}`,
			payload: event.target.checked,
		});
	};

	const commitChange = (
		event: React.SyntheticEvent | Event,
		newValue: number | Array<number>
	) => {
		postEndpoint({
			api,
			user: "demo",
			route: `point/${lightSwitch?.id}`,
			payload: newValue,
		});
	};

	const isSwitch = Boolean(lightSwitch?.dataType === "boolean");

	return (
		<Grid container sx={{ borderBottom: "1px solid rgba(0,0,0,.25)" }}>
			<Grid item xs={12}>
				<ListSubheader
					component="div"
					sx={{ fontWeight: 700, fontSize: ".95rem" }}
				>
					{device?.name || ""}
				</ListSubheader>
			</Grid>
			<Grid item xs={6} sx={{ paddingLeft: "1rem" }}>
				{/* Score */}
				<List sx={classes.list}>
					<ListItem dense disablePadding sx={{ textAlign: "center" }}>
						<ListItemText
							primary={
								isSwitch
									? checked
										? "On"
										: "Off"
									: `${value} %` || lightSwitch?.data?.displayString || ""
							}
							secondary={lightSwitch?.name || ""}
							sx={{
								".MuiListItemText-primary": {
									fontSize: "1rem",
									color: `#${lightSwitch?.color}`,
									fontWeight: 700,
								},
							}}
						/>
					</ListItem>
				</List>
			</Grid>
			<Grid
				item
				xs={6}
				sx={{ paddingRight: "1rem", display: "flex", justifyContent: "center" }}
			>
				{isSwitch ? (
					<Switch
						checked={checked}
						defaultValue={lightSwitch?.data?.value || 0}
						aria-label="Default"
						onChange={handleChangeSwitch}
					/>
				) : (
					<Slider
						defaultValue={lightSwitch?.data?.value || 0}
						aria-label="Default"
						valueLabelDisplay="auto"
						onChange={handleChange}
						onChangeCommitted={commitChange}
					/>
				)}
			</Grid>
		</Grid>
	);
};
