import React from "react";
import "./App.css";

// Hooks
import { useAppSelector } from "./app/hooks";

// Redux
import { RootState } from "./app/store";
import { fetchClients } from "./features/clients/clientsSlice";

// Firebase
import SignIn from "./pages/SignIn/SignIn";

// MUI
import { AppBar, Button, LinearProgress } from "@mui/material";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { useDispatch } from "react-redux";
import {
	BrowserRouter,
	Redirect,
	Route,
	Switch,
	useParams,
} from "react-router-dom";
import { Space } from "./pages/Space/Space";
import DrawerAppBar from "./components/AppBar/AppBar";
import { Building } from "./pages/Building/Building";
import { DevicePage } from "./pages/Devices/DevicePage/DevicePage";

function App() {
	const auth = useAppSelector((state: RootState) => state.firebase.auth);
	const clients = useAppSelector((state: RootState) => state.clients);
	const profile = useAppSelector((state: any) => state.firebase.profile);
	const dispatch = useDispatch();

	const authenticated = Boolean(
		auth.isLoaded &&
			!auth.isEmpty &&
			profile.isLoaded &&
			!profile.isEmpty &&
			profile?.api
	);

	React.useEffect(() => {
		if (authenticated && clients.status === "") {
			dispatch(fetchClients({ api: profile?.api, type: "private" }));
		}
	}, [auth.isLoaded, auth.isEmpty, authenticated]);

	// Loading auth
	if (!auth.isLoaded) {
		return <LinearProgress />;
	}

	// Not Authenticated
	if (auth.isEmpty) {
		return <SignIn />;
	}

	// Authenticated
	if (!auth.isEmpty) {
		if (clients.status === "" || clients.status === "loading") {
			return <LinearProgress />;
		}

		// Router
		if (clients.status === "succeeded") {
			return (
				<BrowserRouter>
					<Switch>
						<Route path="/">
							<DrawerAppBar>
								<Route exact path="/dashboard">
									<Dashboard />
								</Route>
								<Route exact path="/client/:clientId/building/:buildingId">
									<Building />
								</Route>

								{/* Phased out */}
								<Route
									exact
									path="/client/:clientId/building/:buildingId/space/:spaceId"
								>
									<Space />
								</Route>

								{/* Used this instead */}
								<Route
									exact
									path="/client/:clientId/building/:buildingId/space/:spaceId/devices"
								>
									<DevicePage />
								</Route>
							</DrawerAppBar>
						</Route>
					</Switch>
				</BrowserRouter>
			);
		}

		// Fallback
		return (
			<div>
				<Button variant="contained">Retry Client Fetch</Button>
			</div>
		);
	}

	return <div className="App"></div>;
}

export default App;
