import * as React from "react";

import { AppBar, Grid, useTheme } from "@mui/material";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { IAQHeader } from "../../components/IAQHeader/IAQHeader";
import { find, flatten, sortBy } from "lodash";

import { useParams } from "react-router";
import { Metrics } from "../../components/Metrics/Metrics";
import { DashboardLineItem } from "../../components/DashboardLineItem/DashboardLineItem";
import { DevicesSlider } from "../../components/DevicesSlider/DevicesSlider";
import { fetchDevice } from "../../features/devices/devicesSlice";
import {
	selectBuilding,
	selectClient,
	selectSpace,
} from "../../features/appState/appStateSlice";
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import { SpaceSelector } from "../../components/SpaceSelector/SpaceSelector";

interface ISpaceProps {}

export const Space: React.FC<ISpaceProps> = (props) => {
	// Redux
	const dispatch = useDispatch();
	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const devices = useAppSelector((state: any) => state.devices.data);
	const appState = useAppSelector((state: any) => state.appState);
	const clients: any = useAppSelector((state: RootState) => state.clients.data);
	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);
	// Params
	const params: { clientId: string; buildingId: string; spaceId: string } =
		useParams();
	const { clientId, buildingId, spaceId } = params;

	// Colors
	const theme = useTheme();

	// Data
	const selectedClient = clients[clientId];
	const selectedBuilding = buildings[buildingId];

	const spaces: any = sortBy(
		flatten(selectedBuilding?.floors?.map((floor: any) => floor?.spaces)),
		["name"]
	);

	const space = find(spaces, (space: any) => space.id === spaceId);
	const metadata = space?.metadata;

	React.useEffect(() => {
		if (space?.devices && space.devices.length) {
			space.devices.forEach((device: any) => {
				if (!devices[device]) {
					fetchDevice({ api, buildingId, spaceId, id: device.id });
				}
			});
		}
	}, [devices, buildings, spaces]);

	// Sync appState
	React.useEffect(() => {
		if (!selectedBuilding) {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}

		if (
			selectedBuilding &&
			(appState.selectedClient.id !== clientId ||
				appState.selectedBuilding.id !== buildingId ||
				appState.selectedSpace.id !== spaceId)
		) {
			dispatch(selectSpace(space));
			dispatch(selectBuilding(selectedBuilding));
			dispatch(selectClient(selectedClient));
		}
	}, [selectedBuilding, params, appState.selectedBuilding]);

	return (
		<div>
			<Grid
				container
				style={{
					background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(100,135,199,1) 100%)`,
				}}
			>
				<Grid item xs={12}>
					<SpaceSelector />
				</Grid>
				<Grid
					item
					xs={6}
					sx={{ height: 250, borderRight: "1px solid rgba(0,0,0,.25)" }}
				>
					<IAQHeader metadata={metadata} />
				</Grid>
				<Grid item xs={6}>
					<Metrics metadata={metadata} />
				</Grid>
			</Grid>
			<DashboardLineItem title="Devices">
				<DevicesSlider
					route={`/client/${clientId}/building/${buildingId}/space/${spaceId}/devices`}
				/>
			</DashboardLineItem>
		</div>
	);
};
