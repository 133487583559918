import * as React from "react";
import { ThermostatSingleSP } from "./Thermostat/ThermostatSingleSP";
import { ThermostatMultipleSP } from "./Thermostat/ThermostatMultipleSP";

interface IThermostatProps {
	device: any;
}

export const Thermostat: React.FC<IThermostatProps> = ({ device }) => {
	return device.virtualProfile === "virtualThermostatSingleSp" ? (
		<ThermostatSingleSP device={device} />
	) : (
		<ThermostatMultipleSP device={device} />
	);
};
