import * as React from "react";

import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { green } from "@mui/material/colors";

import { DashboardLineItem } from "../DashboardLineItem/DashboardLineItem";

import { useHistory, useLocation, useParams } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { find, forEach, isArray, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { fetchDevice } from "../../features/devices/devicesSlice";
import { ThermostatSingleSP } from "../Devices/Thermostat/ThermostatSingleSP";
import { ThermostatMultipleSP } from "../Devices/Thermostat/ThermostatMultipleSP";

interface IThermostatDeviceProps {}

export const ThermostatDevice: React.FC<IThermostatDeviceProps> = (props) => {
	const history = useHistory();
	const location = useLocation();
	const params: any = useParams();
	const dispatch = useDispatch();

	const { clientId, buildingId } = params;

	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const appState = useAppSelector((state: RootState) => state.appState);

	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);
	const devices: any = useAppSelector((state: RootState) => state.devices.data);

	// Route Data
	const selectedBuilding = buildings[buildingId];

	const floors = selectedBuilding?.floors;

	// Fetch devices
	React.useEffect(() => {
		if (floors?.length) {
			forEach(floors, (floor: any) => {
				if (floor?.spaces) {
					forEach(floor?.spaces, (space: any) => {
						if (
							space?.devices &&
							isArray(space.devices) &&
							space.devices.length
						) {
							forEach(space.devices, (device: any) => {
								if (!devices[device?.id]) {
									dispatch(
										fetchDevice({
											api,
											buildingId,
											spaceId: space.id,
											id: device.id,
										})
									);
								}
							});
						}
					});
				}
			});
		}
	}, [location.pathname, selectedBuilding, appState.selectedBuilding]);

	return (
		<DashboardLineItem title="Floors" button={"Floor Temperature"}>
			<List
				sx={{ width: "100%", bgcolor: "background.paper" }}
				component="nav"
				aria-labelledby="nested-list-subheader"
				disablePadding
			>
				{floors?.map((floor: any) => (
					<List key={floor.id} disablePadding sx={{ width: "100%" }}>
						<ListItem
							secondaryAction={
								<div style={{ fontWeight: 700 }}>
									{`${floor?.metadata?.temperature?.avg || ""}°F` || ""}
								</div>
							}
						>
							<ListItemButton disableGutters style={{ paddingBottom: 0 }}>
								<ListItemText
									key={floor.id}
									primary={floor.name}
									sx={{ fontWeight: 700 }}
								/>
							</ListItemButton>
						</ListItem>

						<ListItem dense sx={{ flexDirection: "column" }}>
							{floor.spaces?.map((space: any) => {
								return (
									<List
										sx={{ width: "100%" }}
										disablePadding
										component="div"
										key={space.id}
									>
										<ListItem disablePadding disableGutters>
											<ListItemText
												primary={space.name}
												sx={{
													".MuiListItemText-primary": {
														fontWeight: 800,
													},
												}}
											/>
										</ListItem>

										<List>
											{space.devices?.map((device: any) => {
												if (devices && !isEmpty(devices)) {
													if (
														devices[device.id]?.virtualProfile ===
															"virtualThermostat" ||
														devices[device.id]?.virtualProfile ===
															"virtualThermostatSingleSp"
													) {
														let tempDisplay = find(devices[device.id]?.points, [
															"virtualDeviceSlot",
															"tempDisplay",
														]);

														let singleSP =
															devices[device.id]?.virtualProfile ===
															"virtualThermostatSingleSp";

														return (
															<ListItem
																dense
																key={device.id}
																secondaryAction={
																	tempDisplay?.data?.displayString || ""
																}
															>
																{singleSP ? (
																	<ThermostatSingleSP
																		device={devices[device.id]}
																	/>
																) : (
																	<ThermostatMultipleSP
																		device={devices[device.id]}
																	/>
																)}
															</ListItem>
														);
													}
												} else {
													return null;
												}
											})}
										</List>
									</List>
								);
							})}
						</ListItem>
					</List>
				))}
			</List>
		</DashboardLineItem>
	);
};
