import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
	selectBuilding,
	selectClient,
	selectSpace,
} from "../../features/appState/appStateSlice";

// Util
import { sortBy } from "lodash";
import { resetWeather } from "../../features/weather/weatherSlice";
import { useHistory } from "react-router-dom";

interface IClientSelectorProps {}

export const ClientSelector: React.FC<IClientSelectorProps> = (props) => {
	const history = useHistory();
	// State
	const dispatch: any = useDispatch();
	const appState: any = useAppSelector((state: RootState) => state.appState);
	const clients: any = useAppSelector((state: RootState) => state.clients);

	// Client Menu
	const clientList: any =
		sortBy(Object.values(clients?.data), ["name"])
			.map((client: any) => client)
			.filter((client: any) => client?.sites?.length) || [];

	const handleChange = (client: { id: string; name: string }) => {
		// Reset weather
		dispatch(resetWeather({}));
		// Reset Building / Space on Client Change
		dispatch(
			selectBuilding({
				id: "",
				name: "",
				clientId: "",
				clientName: "",
				siteId: "",
				siteName: "",
			})
		);

		dispatch(
			selectSpace({
				id: "",
				name: "",
				buildingId: "",
				clientId: "",
			})
		);

		// Change Client
		dispatch(selectClient(client));
		history.push("/dashboard");
	};

	React.useEffect(() => {
		// Auto-select Client if none selected
		if (appState?.selectedClient?.id === "") {
			dispatch(selectClient(clientList[0]));
		}
	}, [appState?.selectedClient?.id]);

	return (
		<div style={{ margin: ".5rem" }}>
			<Box sx={{ minWidth: 120 }}>
				<FormControl fullWidth>
					<InputLabel id="demo-simple-select-label">Client</InputLabel>
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={appState.selectedClient?.id || ""}
						label="Client"
					>
						{clientList?.map((client: any) => (
							<MenuItem
								key={client.id}
								value={client.id}
								onClick={() => handleChange(client)}
							>
								{client?.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</div>
	);
};
