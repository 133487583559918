import * as React from "react";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

import { find, flatten, sortBy } from "lodash";

import { Typography, Button, Tooltip } from "@mui/material";
import { orange, green, lightBlue, purple, blue } from "@mui/material/colors";
import {
	Blinds,
	BlurOn,
	Lightbulb,
	Masks,
	Thermostat,
} from "@mui/icons-material";

import { useHistory, useParams } from "react-router";

interface anysSliderProps {
	route: string;
}

export const DevicesSlider: React.FC<anysSliderProps> = ({ route }) => {
	const params: any = useParams();
	const history = useHistory();

	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);
	const appState = useAppSelector((state: RootState) => state.appState);

	const buildingId = params?.buildingId || appState.selectedBuilding?.id;
	const selectedBuilding = buildings[buildingId];

	const spaces: any = sortBy(
		flatten(selectedBuilding?.floors?.map((floor: any) => floor?.spaces)),
		["name"]
	);

	const space = find(
		spaces,
		(space: any) =>
			space.id === params?.selectedSpace || appState.selectedSpace?.id
	);

	// Control for no devices
	if (!space || !space?.devices || !Boolean(space?.devices.length)) {
		return (
			<Typography
				variant="button"
				style={{ padding: "1rem", width: "100%" }}
				align="center"
			>
				No devices found
			</Typography>
		);
	}
	console.log(space?.devices);

	// If devices found
	//# 						DEVICES		 						//
	//1 AIR QUALITY 												//
	const airQualityDevices =
		space?.devices.filter((device: any) => {
			return device.virtualProfile === "virtualAirQuality";
		}) || [];

	//2 DENSITY	 													//
	const densityDevices =
		space?.devices.filter((device: any) => {
			return device.virtualProfile === "virtualDensityIo";
		}) || [];

	//3 LIGHTING 													//
	const lightingDevices =
		space?.devices.filter((device: any) => {
			return device.virtualProfile === "virtualLightSwitch";
		}) || [];

	//4 THERMOSTATS 												//
	const thermostatDevices =
		space?.devices.filter((device: any) => {
			return (
				device.virtualProfile === "virtualThermostat" ||
				device.virtualProfile === "virtualThermostatSingleSp"
			);
		}) || [];

	//5 THERMOSTATS 												//
	const blindsDevices =
		space?.devices.filter((device: any) => {
			return device.virtualProfile === "virtualBlinds";
		}) || [];

	return (
		<div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
			{/* IAQ */}
			<Tooltip title="IAQ">
				<span>
					<Button
						sx={{ bgcolor: green[500], marginRight: ".5rem" }}
						variant="contained"
						onClick={() => history.push(`${route}?device=iaq`)}
						disabled={!Boolean(airQualityDevices.length)}
					>
						<Masks />
					</Button>
				</span>
			</Tooltip>

			{/* Density */}
			<Tooltip title="Density">
				<span>
					<Button
						sx={{ bgcolor: lightBlue[500], marginRight: ".5rem" }}
						variant="contained"
						onClick={() => history.push(`${route}?device=density`)}
						disabled={!Boolean(densityDevices.length)}
					>
						<BlurOn />
					</Button>
				</span>
			</Tooltip>

			{/* Lighting */}
			<Tooltip title="Lights">
				<span>
					<Button
						sx={{ bgcolor: purple[500], marginRight: ".5rem" }}
						variant="contained"
						onClick={() => history.push(`${route}?device=lights`)}
						disabled={!Boolean(lightingDevices.length)}
					>
						<Lightbulb />
					</Button>
				</span>
			</Tooltip>

			{/* Thermostat */}
			<Tooltip title="Thermostat">
				<span>
					<Button
						sx={{ bgcolor: orange[500], marginRight: ".5rem" }}
						variant="contained"
						onClick={() => history.push(`${route}?device=thermostat`)}
						disabled={!Boolean(thermostatDevices.length)}
					>
						<Thermostat />
					</Button>
				</span>
			</Tooltip>

			{/* Blinds */}
			<Tooltip title="Blinds">
				<span>
					<Button
						sx={{ bgcolor: blue[500], marginRight: ".5rem" }}
						variant="contained"
						onClick={() => history.push(`${route}?device=blinds`)}
						disabled={!Boolean(thermostatDevices.length)}
					>
						<Blinds />
					</Button>
				</span>
			</Tooltip>
		</div>
	);
};
