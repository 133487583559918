import * as React from "react";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { flatten, sortBy } from "lodash";
import { selectSpace } from "../../features/appState/appStateSlice";
import { useDispatch } from "react-redux";

interface ISpacesSliderProps {}

export const SpacesSlider: React.FC<ISpacesSliderProps> = (props) => {
	const dispatch = useDispatch();

	const handleChange = (space: any) => {
		dispatch(selectSpace(space));
	};

	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);
	const appState = useAppSelector((state: RootState) => state.appState);

	const buildingId = appState.selectedBuilding?.id;
	const selectedBuilding = buildings[buildingId];

	const spaces: any = sortBy(
		flatten(selectedBuilding?.floors?.map((floor: any) => floor?.spaces)),
		["name"]
	);

	React.useEffect(() => {
		if (appState.selectedSpace.id === "") {
			if (spaces.length) {
				dispatch(selectSpace(spaces[0]));
			}
		}
	}, [buildings, appState]);

	// Make sure spaces are loaded and default is selected before rendering tabs
	if (!appState.selectedSpace.id || !Boolean(spaces.length)) {
		return <div />;
	}

	return (
		<Box sx={{ maxWidth: { xs: 370, sm: 480 }, bgcolor: "background.paper" }}>
			{Boolean(appState.selectedSpace.id) && (
				<Tabs
					value={appState.selectedSpace.id}
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
				>
					{spaces.map((space: any) => (
						<Tab
							key={space.id}
							value={space.id}
							label={space.name}
							onClick={() => handleChange(space)}
						/>
					))}
				</Tabs>
			)}
		</Box>
	);
};
