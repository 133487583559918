import * as React from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { find, flatten, isEmpty, sortBy } from "lodash";
import {
	List,
	ListItem,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";

interface IDashboardSpaceMetadataProps {}

export const DashboardSpaceMetadata: React.FC<IDashboardSpaceMetadataProps> = (
	props
) => {
	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);
	const appState = useAppSelector((state: RootState) => state.appState);

	const buildingId = appState.selectedBuilding?.id;
	const selectedBuilding = buildings[buildingId];

	const spaces: any = sortBy(
		flatten(selectedBuilding?.floors?.map((floor: any) => floor?.spaces)),
		["name"]
	);

	const space = find(
		spaces,
		(space: any) => space.id === appState.selectedSpace?.id
	);

	const iaq = space?.metadata?.airQuality;
	const temp = space?.metadata?.temperature;

	if (!space?.metadata || isEmpty(space.metadata)) {
		<List>
			<ListItem>
				<ListItemText primary="IAQ" secondary={"No Available Metadata"} />
			</ListItem>
		</List>;
	}

	return (
		<div>
			<List sx={{ width: "100%" }}>
				{/* IAQ */}
				{iaq && !isEmpty(iaq) && (
					<ListItem
						sx={{ width: "100%" }}
						secondaryAction={
							<Typography
								sx={{
									fontWeight: 800,
									color: Number(iaq?.avg) > 90 ? green[500] : "black",
								}}
							>
								{iaq?.avg}%
							</Typography>
						}
					>
						<ListItemText primary="IAQ" />
					</ListItem>
				)}

				{/* Temperature */}
				{temp && !isEmpty(temp) && (
					<ListItem
						secondaryAction={<Typography>{temp?.avg}F</Typography>}
						sx={{
							width: "100%",
						}}
					>
						<ListItemText primary="Temperature" />
					</ListItem>
				)}
			</List>
		</div>
	);
};
