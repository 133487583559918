import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";

import { auth } from "../../firebase/firebase";
import { ClientSelector } from "../ClientSelector/ClientSelector";
import { BuildingSelector } from "../BuildingSelector/BuildingSelector";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	alignItems: "flex-start",
	paddingTop: theme.spacing(1),
	paddingBottom: theme.spacing(2),
	// Override media queries injected by theme.mixins.toolbar
	"@media all": {
		minHeight: 100,
	},
}));

interface Props {
	window?: () => Window;
	children?: any;
}

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

export default function DrawerAppBar(props: Props) {
	const { window, children } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const history = useHistory();

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	// Redirect to dashboard on initial load
	const location = useLocation();
	React.useEffect(() => {
		if (location.pathname === "/") {
			history.push("/dashboard");
		}
	}, []);

	const drawer = (
		<Grid sx={{ textAlign: "center" }}>
			<div style={{ marginTop: ".5em" }} />
			<ClientSelector />
			<Divider />
			<List>
				{navItems.map((item) => (
					<ListItem key={item} disablePadding>
						<ListItemButton sx={{ textAlign: "center" }}>
							<ListItemText primary={item} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<List>
				<ListItem disablePadding>
					<ListItemButton
						sx={{ textAlign: "center" }}
						onClick={() => auth.signOut()}
					>
						<ListItemText primary={"Sign Out"} />
					</ListItemButton>
				</ListItem>
			</List>
		</Grid>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	const theme = useTheme();

	return (
		<React.Fragment>
			<Grid
				style={{
					flexGrow: 1,
					width: "100%",
				}}
			>
				<div
					style={{
						background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(100,135,199,1) 100%)`,
					}}
				>
					<AppBar
						component="nav"
						position="static"
						sx={{
							borderBottom: "1px solid rgba(0,0,0,.5)",
							boxShadow: theme.shadows[5],
						}}
					>
						<StyledToolbar>
							{
								// Read location, add /home link
								Boolean(
									location.pathname !== "/dashboard" || location.pathname !== ""
								) && (
									<Typography
										variant="body1"
										component="div"
										sx={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											justifyContent: "center",
											height: "48px",
										}}
										onClick={() => history.push("/dashboard")}
									>
										Home /
									</Typography>
								)
							}
							<Typography
								variant="h6"
								component="div"
								sx={{ flexGrow: 1, display: { sm: "block" } }}
							>
								<BuildingSelector />
							</Typography>

							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 2 }}
							>
								<MenuIcon />
							</IconButton>
						</StyledToolbar>
					</AppBar>
					<nav>
						<Drawer
							anchor="right"
							container={container}
							variant="temporary"
							open={mobileOpen}
							onClose={handleDrawerToggle}
							ModalProps={{
								keepMounted: true,
							}}
							sx={{
								display: { xs: "block" },
								"& .MuiDrawer-paper": {
									boxSizing: "border-box",
									width: drawerWidth,
								},
							}}
						>
							{drawer}
						</Drawer>
					</nav>
				</div>
				{children}
			</Grid>
		</React.Fragment>
	);
}
