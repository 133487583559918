import * as React from "react";

import { Weather } from "../../components/Weather/Weather";

// Components
import { Grid, IconButton, useTheme } from "@mui/material";

// State
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";

import { IAQHeader } from "../../components/IAQHeader/IAQHeader";
import { SpacesSlider } from "../../components/SpacesSlider/SpacesSlider";
import { DashboardLineItem } from "../../components/DashboardLineItem/DashboardLineItem";
import { DevicesSlider } from "../../components/DevicesSlider/DevicesSlider";

import { DashboardSpaceMetadata } from "../../components/DashboardSpaceMetadata/DashboardSpaceMetadata";
import { Info, NavigateNext } from "@mui/icons-material";

import { useHistory, useLocation } from "react-router-dom";

interface IDashboardProps {}

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Dashboard: React.FC<IDashboardProps> = (props) => {
	//History
	const history = useHistory();

	// Colors
	const theme = useTheme();

	// State
	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);
	const appState = useAppSelector((state: RootState) => state.appState);

	// Data
	const clientId = appState.selectedClient?.id;
	const buildingId = appState.selectedBuilding?.id;
	const spaceId = appState.selectedSpace?.id;

	const selectedBuilding = buildings[buildingId];
	const metadata = selectedBuilding?.metadata;

	return (
		<div>
			<Grid
				container
				style={{
					background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(100,135,199,1) 100%)`,
					height: 250,
				}}
			>
				<Grid
					item
					xs={6}
					sx={{ height: 250, borderRight: "1px solid rgba(0,0,0,.25)" }}
				>
					<Weather />
				</Grid>
				<Grid item xs={6}>
					<IAQHeader metadata={metadata} />
				</Grid>
			</Grid>

			{/* Devices */}
			<DashboardLineItem title="Building Devices">
				<DevicesSlider route={`/client/${clientId}/building/${buildingId}`} />
			</DashboardLineItem>

			{/* Spaces */}
			<DashboardLineItem title="Spaces">
				<SpacesSlider />
			</DashboardLineItem>

			{/* IAQ Meta */}
			<DashboardLineItem
				title={`${appState.selectedSpace?.name || ""} Info`}
				button={
					<IconButton
						onClick={() =>
							history.push(
								`/client/${clientId}/building/${buildingId}/space/${spaceId}/devices?device=iaq`
							)
						}
					>
						<Info color="primary" />
					</IconButton>
				}
			>
				<DashboardSpaceMetadata />
			</DashboardLineItem>
		</div>
	);
};
