import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

import "firebase/firestore"; // <- needed if using firestore
import "firebase/functions"; // <- needed if using httpsCallable

const firebaseConfig = {
	apiKey: "AIzaSyCwzOQwEhsdE2bAJJn3mq_9G7hjCH90Ss4",
	authDomain: "tapa-production.firebaseapp.com",
	databaseURL: "https://tapa-production.firebaseio.com",
	projectId: "tapa-production",
	storageBucket: "tapa-production.appspot.com",
	messagingSenderId: "619069624986",
	appId: "1:619069624986:web:53a253d71211b5ded80523",
};

const app = firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, database, functions, storage, store };
