import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { functions } from "../../firebase/firebase";

const getWeather = functions.httpsCallable("getWeather");

export const fetchWeather: any = createAsyncThunk(
	"weather/fetchWeather",
	async ({ address, id }: { address: string; id?: string }) => {
		return getWeather({ address }).then((res: any) => {
			// If data, set and run again if given address changes
			if (res?.data?.properties) {
				return { id, weather: res.data.properties.periods };
			}
		});
	}
);

const initialState = {
	data: {},
	status: "",
	error: "",
};

export const weatherSlice = createSlice({
	name: "weather",
	initialState,
	reducers: {
		resetWeather: (state, action) => {
			state = initialState;
		},
	},
	extraReducers: {
		[fetchWeather.pending]: (state, action) => {
			state.status = "loading";
		},
		[fetchWeather.fulfilled]: (state: any, action: any) => {
			state.status = "succeeded";
			if (action.payload?.id) {
				state.data[action.payload.id] = action.payload.weather;
			}
		},
		[fetchWeather.rejected]: (state, action) => {
			state.status = "failed";
			state.error = action.error.message;
		},
	},
});

export const { resetWeather } = weatherSlice.actions;

export default weatherSlice.reducer;
