import React from "react";

// MUI
import { Grid, Typography } from "@mui/material";

// Redux
import { isEmpty } from "lodash";
import { Info } from "@mui/icons-material";
import { Button } from "@mui/material";

const classes = {
	wrapper: {
		position: "relative",
		padding: "1rem",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
	} as React.CSSProperties,
};

interface IMetricsProps {
	metadata: {
		airQuality: {
			high: string;
			avg: string;
			low: string;
		};
		co2?: {
			high: string;
			avg: string;
			low: string;
		};
		temperature?: {
			high: string;
			avg: string;
			low: string;
		};
	};
}

export const Metrics: React.FC<IMetricsProps> = ({ metadata }) => {
	// State

	// Redux

	const co2 = metadata?.co2;

	// If no metadata...
	if (isEmpty(co2)) {
		return (
			<div style={classes.wrapper}>
				<div>
					{/* IAQ Title */}
					<Typography variant="body1" align="center" sx={{ color: "white" }}>
						Carbon Dioxide
					</Typography>
					<Typography
						variant="h6"
						align="center"
						style={{
							fontWeight: 700,
							color: "white",
							marginTop: "2rem",
						}}
					>
						No Metadata
					</Typography>
				</div>
			</div>
		);
	}

	// Else show data
	return (
		<div style={classes.wrapper}>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				{/* IAQ Title */}
				<Typography variant="body1" align="center" sx={{ color: "white" }}>
					Carbon Dioxide
				</Typography>

				{/* IAQ wrapper */}
				<Grid
					container
					sx={{
						alignItems: "center",
						justifyContent: "center",
						color: "white",
					}}
				>
					{/* Average Air Quality */}
					{Boolean(true) && (
						<Typography
							variant="h2"
							style={{
								fontWeight: 700,
								marginLeft: ".5rem",
							}}
						>
							{Math.round(Number(co2?.avg))}
						</Typography>
					)}
				</Grid>

				{/* High / Low */}
				<Typography
					align="center"
					sx={{
						display: "flex",
						justifyContent: "center",
						paddingBottom: "1rem",
						color: "white",
					}}
				>
					+{Math.round(Number(co2?.high))}/-
					{Math.round(Number(co2?.low))}
				</Typography>

				{/* Report Button */}
				{Boolean(Number(co2?.avg) >= 400) && (
					<Button variant="contained" startIcon={<Info />} color="warning">
						Warning
					</Button>
				)}
			</div>

			{/* Description */}
			<Typography
				align="center"
				width={"100%"}
				style={{ lineHeight: 1, alignItems: "center", color: "white" }}
			>
				{}
			</Typography>
		</div>
	);
};
