import * as React from "react";

import { Grid, ListItem, ListItemText, Typography } from "@mui/material";

interface IDashboardLineItemProps {
	title: string;
	button?: any;
	children?: any;
}

export const DashboardLineItem: React.FC<IDashboardLineItemProps> = ({
	button,
	title,
	children,
}) => {
	return (
		<Grid container style={{ borderBottom: "1px solid rgba(0,0,0,.25)" }}>
			<Grid item style={{ padding: ".5rem", width: "100%" }}>
				{button ? (
					<ListItem
						style={{ padding: 0, fontWeight: 800 }}
						secondaryAction={button}
					>
						<ListItemText
							primary={title}
							sx={{
								".MuiListItemText-primary": {
									fontWeight: 800,
								},
							}}
						/>
					</ListItem>
				) : (
					<ListItem style={{ padding: 0 }}>
						<ListItemText
							primary={title}
							sx={{
								".MuiListItemText-primary": {
									fontWeight: 800,
								},
							}}
						/>
					</ListItem>
				)}
			</Grid>
			<Grid item style={{ paddingBottom: "1rem", width: "100%" }}>
				{children}
			</Grid>
		</Grid>
	);
};
