import React from "react";

// MUI
import { Avatar, Grid, Typography, LinearProgress } from "@mui/material";
import { Air, DeviceThermostat } from "@mui/icons-material";

// Redux
import { useAppSelector } from "../../app/hooks";
import { useDispatch } from "react-redux";
import { RootState } from "../../app/store";

// Actions
import { fetchWeather } from "../../features/weather/weatherSlice";
import { isEmpty } from "lodash";

interface IWeatherProps {}

export const Weather: React.FC<IWeatherProps> = () => {
	// Redux
	const dispatch = useDispatch();
	const appState = useAppSelector((state: RootState) => state.appState);
	const weather: any = useAppSelector((state: RootState) => state.weather); //buildings fetched and stored on selection
	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);

	const buildingId = appState?.selectedBuilding?.id;
	const selectedBuilding = buildings[buildingId];

	// Weather fetch / refresh
	React.useEffect(() => {
		if (selectedBuilding?.address) {
			let address = `${selectedBuilding.address}, ${selectedBuilding.city}, ${selectedBuilding.state}, ${selectedBuilding.zip}`;
			dispatch(fetchWeather({ address, id: buildingId }));
		}

		// eslint-disable-next-line
	}, [buildingId, selectedBuilding?.id]);

	const weatherData = weather?.data[buildingId]?.[0] || {};
	const icon =
		weatherData?.icon?.slice(0, weatherData?.icon?.length - 5) + "large";
	const wind =
		weatherData?.windSpeed && weatherData?.windDirection
			? String(weatherData?.windSpeed + " " + weatherData?.windDirection)
			: "";

	if (Boolean(weather.status === "loading")) {
		return <LinearProgress />;
	}

	if (!weatherData || isEmpty(weatherData)) {
		return <div />;
	}

	return (
		<div
			style={{
				position: "relative",
				padding: "1rem",
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<div>
				{/* City Wrapper */}
				<Typography variant="body1" align="center" sx={{ color: "white" }}>
					{selectedBuilding?.state || ""}
				</Typography>

				{/* Icon / Temp wrapper */}
				<Grid
					container
					sx={{
						alignItems: "center",
						justifyContent: "center",
						color: "white",
					}}
				>
					{/* Icon */}
					{icon && (
						<Avatar
							sx={{ width: 56, height: 56, marginRight: ".5rem" }}
							src={icon}
						>
							<DeviceThermostat />
						</Avatar>
					)}
					{/* Temperature */}
					{Boolean(weatherData?.temperature) && (
						<Typography
							variant="h2"
							style={{
								fontWeight: 700,
								marginLeft: ".5rem",
							}}
						>
							{`${
								weatherData.temperature ? weatherData.temperature + "°" : ""
							}`}
						</Typography>
					)}
				</Grid>

				{/* Wind */}
				<Typography
					align="center"
					sx={{
						display: "flex",
						justifyContent: "center",
						paddingBottom: "1rem",
						color: "white",
					}}
				>
					{<Air />}
					{wind}
				</Typography>
			</div>

			{/* Description */}
			<Typography
				align="center"
				width={"100%"}
				style={{ lineHeight: 1, alignItems: "center", color: "white" }}
			>
				{weatherData.shortForecast ? weatherData.shortForecast : "Forecast"}
			</Typography>
		</div>
	);
};
