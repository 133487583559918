import * as React from "react";

// Components
import {
	Grid,
	useTheme,
	List,
	ListItemButton,
	ListItemText,
	ListItem,
} from "@mui/material";

// State
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useDispatch } from "react-redux";

import { IAQHeader } from "../../components/IAQHeader/IAQHeader";
import { DashboardLineItem } from "../../components/DashboardLineItem/DashboardLineItem";

import { DashboardSpaceMetadata } from "../../components/DashboardSpaceMetadata/DashboardSpaceMetadata";

import { useHistory, useParams, useLocation } from "react-router-dom";
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import {
	selectBuilding,
	selectClient,
	selectSpace,
} from "../../features/appState/appStateSlice";
import { green } from "@mui/material/colors";
import { DevicesSlider } from "../../components/DevicesSlider/DevicesSlider";
import { IAQDevice } from "../../components/BuildingDevices/IAQDevice";
import { ThermostatDevice } from "../../components/BuildingDevices/ThermostatDevice";
import { flatten, sortBy } from "lodash";
import { LightDevice } from "../../components/BuildingDevices/LightDevice";
import { BlindsDevice } from "../../components/BuildingDevices/BlindsDevice";

interface IBuildingProps {}

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Building: React.FC<IBuildingProps> = (props) => {
	// Router
	const history = useHistory();
	const params: any = useParams();
	const { search } = useLocation();
	const queryParams = useQuery();
	let activeDeviceType = queryParams.get("device");

	// Theme
	const theme = useTheme();

	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const dispatch = useDispatch();
	const appState = useAppSelector((state: RootState) => state.appState);

	// Redux Data
	const clients: any = useAppSelector((state: RootState) => state.clients.data);
	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);

	// Route Data
	const clientId = params?.clientId;
	const selectedClient = clients[clientId];

	const buildingId = params?.buildingId;
	const selectedBuilding = buildings[buildingId];
	const metadata = selectedBuilding?.metadata;

	const floors = selectedBuilding?.floors;

	// Sync appState
	React.useEffect(() => {
		if (!selectedBuilding) {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}

		if (
			selectedBuilding &&
			(appState.selectedClient.id !== clientId ||
				appState.selectedBuilding.id !== buildingId)
		) {
			dispatch(selectBuilding(selectedBuilding));
			dispatch(selectClient(selectedClient));
		}

		if (
			appState.selectedClient.id &&
			appState.selectedBuilding.id &&
			!appState.selectedSpace.id
		) {
			let spaces: any = sortBy(
				flatten(floors?.map((floor: any) => floor?.spaces)),
				["name"]
			);

			dispatch(selectSpace(spaces[0]));
		}
	}, [
		selectedBuilding,
		params,
		appState.selectedBuilding,
		appState.selectedSpace,
	]);

	return (
		<div>
			<Grid
				container
				style={{
					background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(100,135,199,1) 100%)`,
					height: 250,
				}}
			>
				<Grid item xs={12}>
					<IAQHeader metadata={metadata} />
				</Grid>
			</Grid>
			{/* IAQ Meta */}
			<DashboardLineItem title={``}>
				<DevicesSlider route={`/client/${clientId}/building/${buildingId}`} />
			</DashboardLineItem>

			{/* IAQ Filter */}
			{Boolean(activeDeviceType === "iaq") && <IAQDevice />}
			{Boolean(activeDeviceType === "thermostat") && <ThermostatDevice />}
			{Boolean(activeDeviceType === "lights") && <LightDevice />}
			{Boolean(activeDeviceType === "blinds") && <BlindsDevice />}

			{/* Devices */}
		</div>
	);
};
