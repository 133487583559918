import * as React from "react";

import { Grid, List, ListItem, ListItemText, Slider } from "@mui/material";
import { find, isArray } from "lodash";

import { functions } from "../../../firebase/firebase";
import { useAppSelector } from "../../../app/hooks";

const postEndpoint = functions.httpsCallable("postEndpoint");

// Styles
const classes = {
	list: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",
	},
};
interface IThermostatMultipleSPProps {
	device: any;
}

export const ThermostatMultipleSP: React.FC<IThermostatMultipleSPProps> = ({
	device,
}) => {
	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const points = device?.points || [];

	// Find individual iaq data points
	const tempDisplay = find(points, ["virtualDeviceSlot", "tempDisplay"]);
	const status = find(points, ["virtualDeviceSlot", "status"]);
	const humidity = find(points, ["virtualDeviceSlot", "humidity"]);

	const tempIncrease = find(points, ["virtualDeviceSlot", "tempIncrease"]);
	const tempDecrease = find(points, ["virtualDeviceSlot", "tempDecrease"]);

	const [value, setValue] = React.useState<number[]>([0, 0]);

	React.useEffect(() => {
		if (tempIncrease?.data && tempDecrease?.data) {
			setValue([tempIncrease.data.value, tempDecrease.data.value]);
		}
	}, [device]);

	const handleChange = (event: Event, newValue: number | number[]) => {
		setValue(newValue as number[]);
	};

	const commitChange = (
		event: React.SyntheticEvent | Event,
		newValue: number | Array<number>
	) => {
		if (isArray(newValue)) {
			if (value[0] !== newValue[0]) {
				postEndpoint({
					api,
					user: "demo",
					route: `point/${tempIncrease?.id}`,
					payload: newValue,
				});
			} else {
				postEndpoint({
					api,
					user: "demo",
					route: `point/${tempDecrease?.id}`,
					payload: newValue,
				});
			}
		}
	};

	return (
		<Grid container sx={{ borderBottom: "1px solid rgba(0,0,0,.25)" }}>
			<Grid item xs={12}>
				<List sx={classes.list}>
					<ListItem dense disablePadding sx={{ textAlign: "center" }}>
						<ListItemText
							primary={device?.name || ""}
							secondary={
								`${tempDisplay?.data.displayString || "No Data"} | ${
									humidity?.data.displayString || ""
								} Humidity` || ""
							}
							sx={{
								".MuiListItemText-primary": {
									fontSize: "1rem",
									fontWeight: 700,
								},
							}}
						/>
					</ListItem>
				</List>
			</Grid>
			<Grid item xs={6} sx={{ paddingLeft: "1rem" }}>
				{/* Score */}
				<List sx={classes.list}>
					<ListItem dense disablePadding sx={{ textAlign: "center" }}>
						<ListItemText
							primary={`${value[0]}° F to ${value[1]}° F` || "No Score"}
							secondary={status?.data.displayString || ""}
							sx={{
								".MuiListItemText-primary": {
									fontSize: "1rem",
									fontWeight: 700,
								},
							}}
						/>
					</ListItem>
				</List>
			</Grid>
			<Grid
				item
				xs={6}
				sx={{ paddingRight: "2rem", display: "flex", alignItems: "center" }}
			>
				<Slider
					min={65}
					max={78}
					value={value}
					aria-label="Default"
					valueLabelDisplay="auto"
					onChange={handleChange}
					onChangeCommitted={commitChange}
				/>
			</Grid>
		</Grid>
	);
};
