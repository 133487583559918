import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { fetchDevice } from "../../../features/devices/devicesSlice";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { fetchBuilding } from "../../../features/buildings/buildingsSlice";
import {
	selectBuilding,
	selectClient,
	selectSpace,
} from "../../../features/appState/appStateSlice";
import {
	filter,
	find,
	flatten,
	forEach,
	isArray,
	isEmpty,
	sortBy,
} from "lodash";
import { Grid, LinearProgress, useTheme } from "@mui/material";
import { IAQHeader } from "../../../components/IAQHeader/IAQHeader";
import { SpaceSelector } from "../../../components/SpaceSelector/SpaceSelector";
import { IAQ } from "../../../components/Devices/IAQ";
import { DashboardLineItem } from "../../../components/DashboardLineItem/DashboardLineItem";
import { DevicesSlider } from "../../../components/DevicesSlider/DevicesSlider";
import { Light } from "../../../components/Devices/Light";
import { Thermostat } from "../../../components/Devices/Thermostat";

interface IDevicePageProps {}

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const DevicePage: React.FC<IDevicePageProps> = (props) => {
	// Routing
	const history = useHistory();
	const location = useLocation();

	// Route Params
	const params: {
		clientId: string;
		buildingId: string;
		spaceId: string;
	} = useParams();
	const { clientId, buildingId, spaceId } = params;

	// Search Params
	const queryParams = useQuery();
	let activeDeviceType = queryParams.get("device");

	// Redux
	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const dispatch = useDispatch();

	// Style
	const theme = useTheme();

	// Sync appState
	const appState = useAppSelector((state: any) => state.appState);
	const clients: any = useAppSelector((state: RootState) => state.clients.data);
	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);
	const devices: any = useAppSelector((state: RootState) => state.devices.data);

	const selectedClient = clients[clientId];
	const selectedBuilding = buildings[buildingId];

	const spaces: any = sortBy(
		flatten(selectedBuilding?.floors?.map((floor: any) => floor?.spaces)),
		["name"]
	);
	const space = find(spaces, (space: any) => space.id === spaceId);
	const metadata = space?.metadata;

	React.useEffect(() => {
		if (!selectedBuilding) {
			dispatch(fetchBuilding({ api, id: buildingId }));
		}

		if (
			selectedBuilding &&
			(appState.selectedClient.id !== clientId ||
				appState.selectedBuilding.id !== buildingId ||
				appState.selectedSpace.id !== spaceId)
		) {
			dispatch(selectSpace(space));
			dispatch(selectBuilding(selectedBuilding));
			dispatch(selectClient(selectedClient));
		}
	}, [selectedBuilding, params, appState.selectedBuilding]);
	// Sync appState

	// Fetch devices
	React.useEffect(() => {
		if (space?.devices && isArray(space.devices) && space.devices.length) {
			forEach(space.devices, (device: any) => {
				if (!devices[device?.id]) {
					dispatch(fetchDevice({ api, buildingId, spaceId, id: device.id }));
				}
			});
		}
	}, [location.pathname, selectedBuilding, appState.selectedBuilding]);

	// Handle Loading
	if (devices.status === "loading") {
		return <LinearProgress />;
	}

	// Handle No Devices
	if (isEmpty(devices)) {
		return (
			<Grid
				container
				style={{
					background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(100,135,199,1) 100%)`,
					height: 250,
				}}
			>
				<Grid item xs={6}>
					No Devices to display
				</Grid>
			</Grid>
		);
	}

	// Filter out device types
	const availableDevices = space.devices.map((device: any) => device.id);
	const iaqDevices = filter(
		devices,
		(device: any) =>
			device.virtualProfile === "virtualAirQuality" &&
			availableDevices.includes(device.id)
	);

	const densityDevices = filter(
		devices,
		(device: any) =>
			device.virtualProfile === "virtualDensityIo" &&
			availableDevices.includes(device.id)
	);

	const lightingDevices = filter(
		devices,
		(device: any) =>
			device.virtualProfile === "virtualLightSwitch" &&
			availableDevices.includes(device.id)
	);

	const thermostatDevices = filter(
		devices,
		(device: any) =>
			(device.virtualProfile === "virtualThermostatSingleSp" ||
				device.virtualProfile === "virtualThermostat") &&
			availableDevices.includes(device.id)
	);

	// Return Devices
	return (
		<div>
			<Grid
				container
				style={{
					background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, rgba(100,135,199,1) 100%)`,
					height: 250,
				}}
			>
				<Grid item xs={12}>
					<SpaceSelector />
				</Grid>
				<Grid item xs={12}>
					<IAQHeader metadata={metadata} />
				</Grid>

				<DashboardLineItem title="">
					<DevicesSlider
						route={`/client/${clientId}/building/${buildingId}/space/${spaceId}/devices`}
					/>
				</DashboardLineItem>

				{/* Devices */}
				{iaqDevices.map((device: any) => {
					if (activeDeviceType === "iaq") {
						return <IAQ device={devices[device.id]} key={device.id} />;
					} else {
						return null;
					}
				})}

				{lightingDevices.map((device: any) => {
					if (activeDeviceType === "lights") {
						return <Light device={devices[device.id]} key={device.id} />;
					} else {
						return null;
					}
				})}

				{thermostatDevices.map((device: any) => {
					if (activeDeviceType === "thermostat") {
						return <Thermostat device={devices[device.id]} key={device.id} />;
					} else {
						return null;
					}
				})}
			</Grid>
		</div>
	);
};
