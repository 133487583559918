import * as React from "react";

import { Grid, List, ListItem, ListItemText, Slider } from "@mui/material";
import { find } from "lodash";

import { functions } from "../../../firebase/firebase";
import { useAppSelector } from "../../../app/hooks";

const postEndpoint = functions.httpsCallable("postEndpoint");

// Styles
const classes = {
	list: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",
	},
};
interface IThermostatSingleSPProps {
	device: any;
}

export const ThermostatSingleSP: React.FC<IThermostatSingleSPProps> = ({
	device,
}) => {
	const api = useAppSelector((state: any) => state.firebase.profile.api);
	const points = device?.points || [];

	// Find individual iaq data points
	const tempDisplay = find(points, ["virtualDeviceSlot", "tempDisplay"]);
	const status = find(points, ["virtualDeviceSlot", "status"]);
	const setpoint = find(points, ["virtualDeviceSlot", "setpoint"]);
	const humidity = find(points, ["virtualDeviceSlot", "humidity"]);

	let [value, setValue] = React.useState(tempDisplay?.data?.value || 0);
	const handleChange = (event: Event, newValue: number | number[]) => {
		setValue(newValue);
	};

	const commitChange = (
		event: React.SyntheticEvent | Event,
		newValue: number | Array<number>
	) => {
		postEndpoint({
			api,
			user: "demo",
			route: `point/${setpoint?.id}`,
			payload: newValue,
		});
	};

	return (
		<Grid container sx={{ borderBottom: "1px solid rgba(0,0,0,.25)" }}>
			<Grid item xs={12}>
				<List sx={classes.list}>
					<ListItem dense disablePadding sx={{ textAlign: "center" }}>
						<ListItemText
							primary={device?.name || ""}
							secondary={
								`${tempDisplay?.data.displayString || "No Data"} | ${
									humidity?.data.displayString || ""
								} Humidity` || ""
							}
							sx={{
								".MuiListItemText-primary": {
									fontSize: "1rem",
									fontWeight: 700,
								},
							}}
						/>
					</ListItem>
				</List>
			</Grid>
			<Grid item xs={6} sx={{ paddingLeft: "1rem" }}>
				{/* Score */}
				<List sx={classes.list}>
					<ListItem dense disablePadding sx={{ textAlign: "center" }}>
						<ListItemText
							primary={`${setpoint?.data?.value}° F` || "No Score"}
							secondary={status?.data.displayString || ""}
							sx={{
								".MuiListItemText-primary": {
									fontSize: "1rem",
									fontWeight: 700,
								},
							}}
						/>
					</ListItem>
				</List>
			</Grid>
			<Grid
				item
				xs={6}
				sx={{ paddingRight: "2rem", display: "flex", alignItems: "center" }}
			>
				<Slider
					defaultValue={tempDisplay?.data?.value || 0}
					aria-label="Default"
					valueLabelDisplay="auto"
					onChange={handleChange}
					onChangeCommitted={commitChange}
				/>
			</Grid>
		</Grid>
	);
};
