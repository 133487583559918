import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
	selectBuilding,
	selectSpace,
} from "../../features/appState/appStateSlice";

// Util
import { find, flatten, sortBy } from "lodash";
import { fetchBuilding } from "../../features/buildings/buildingsSlice";
import { resetWeather } from "../../features/weather/weatherSlice";

import { useHistory } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

interface ISpaceSelector {}

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const SpaceSelector: React.FC<ISpaceSelector> = (props) => {
	const history = useHistory();
	const query = useQuery();

	const params: any = useParams();
	const location = useLocation();
	const { clientId, buildingId, spaceId } = params;

	// State
	const dispatch: any = useDispatch();
	const appState: any = useAppSelector((state: RootState) => state.appState);

	// Data
	const api: any = useAppSelector((state: any) => state.firebase.profile?.api);
	const buildings: any = useAppSelector(
		(state: RootState) => state.buildings.data
	);

	// Data
	const selectedBuilding = buildings[buildingId];

	const spaces: any = sortBy(
		flatten(selectedBuilding?.floors?.map((floor: any) => floor?.spaces)),
		["name"]
	);

	const handleChange = (space: any) => {
		history.push(
			`/client/${clientId}/building/${buildingId}/space/${
				space?.id
			}/devices?${query.toString()}`
		);
	};

	return (
		<div style={{ margin: ".5rem" }}>
			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<FormControl>
					<Select
						fullWidth
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={appState.selectedSpace?.id || ""}
						style={{ color: "white" }}
						variant="standard"
					>
						{spaces?.map((space: any) => (
							<MenuItem
								key={space.id}
								value={space.id}
								onClick={() => handleChange(space)}
							>
								{space?.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</div>
	);
};
