import {
	Grid,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Typography,
} from "@mui/material";
import { find } from "lodash";
import * as React from "react";

interface IIAQProps {
	device: any;
}

// Styles
const classes = {
	list: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",
	},
};

export const IAQ: React.FC<IIAQProps> = ({ device }) => {
	const points = device?.points || [];

	// Find individual iaq data points
	const co2 = find(points, ["virtualDeviceSlot", "co2" || "virtualAirQuality"]); //used to be CO2
	const humidity = find(points, ["virtualDeviceSlot", "humidity"]);
	const pm25 = find(points, ["virtualDeviceSlot", "pm25"]);
	const voc = find(points, ["virtualDeviceSlot", "voc"]);
	const temp = find(points, ["virtualDeviceSlot", "tempDisplay"]);
	const score = find(points, ["virtualDeviceSlot", "airQualityScore"]);

	const scoreOnly = !co2 && !humidity && !pm25 && !voc;

	return (
		<Grid container sx={{ borderBottom: "1px solid rgba(0,0,0,.25)" }}>
			<Grid item xs={12}>
				<ListSubheader
					component="div"
					sx={{ fontWeight: 700, fontSize: ".95rem" }}
				>
					{device?.name || ""}
				</ListSubheader>
			</Grid>
			<Grid item xs={scoreOnly ? 12 : 6} sx={{ paddingLeft: "1rem" }}>
				{/* Score */}
				<List sx={classes.list}>
					<ListItem dense disablePadding sx={{ textAlign: "center" }}>
						<ListItemText
							primary={score?.data?.displayString || ""}
							secondary={score?.name || ""}
							sx={{
								".MuiListItemText-primary": {
									fontSize: "1rem",
									color: `#${score?.color}`,
									fontWeight: 700,
								},
							}}
						/>
					</ListItem>

					{/* Temp */}
					{/* <ListItem dense disablePadding>
						<ListItemText
							primary={temp?.data?.displayString || ""}
							secondary={temp?.name || ""}
						/>
					</ListItem> */}
				</List>
			</Grid>

			{/* If no additional data selected */}
			{!scoreOnly && (
				<>
					<Grid item xs={3}>
						<List dense sx={classes.list}>
							{/* CO2 */}
							<ListItem dense disableGutters disablePadding>
								<ListItemText
									primary={co2?.data?.displayString || ""}
									secondary={co2?.name || ""}
								/>
							</ListItem>

							{/* Humidity */}
							<ListItem dense disableGutters disablePadding>
								<ListItemText
									primary={humidity?.data?.displayString || ""}
									secondary={humidity?.name || ""}
								/>
							</ListItem>
						</List>
					</Grid>
					<Grid item xs={3}>
						<List dense sx={classes.list}>
							{/* PM25 */}
							<ListItem dense disableGutters disablePadding>
								<ListItemText
									primary={pm25?.data?.displayString || ""}
									secondary={pm25?.name || ""}
								/>
							</ListItem>

							{/* VOC */}
							<ListItem dense disableGutters disablePadding>
								<ListItemText
									primary={voc?.data?.displayString || ""}
									secondary={voc?.name || ""}
								/>
							</ListItem>
						</List>
					</Grid>
				</>
			)}
		</Grid>
	);
};
