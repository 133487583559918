import React from "react";
import "./firebase/firebase";

// MUI
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { green, purple } from "@mui/material/colors";

// Firebase
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

// Redux
import { Provider } from "react-redux";
import { rrfProps, store } from "./app/store";

// Components
import App from "./App";

import { createRoot } from "react-dom/client";
import { SnackbarProvider } from "notistack";
const container = document.getElementById("root");
const root = createRoot(container!);

const theme = createTheme({
	palette: {
		primary: {
			main: "#5B67BB",
		},
		secondary: {
			main: green[500],
		},
	},
});

root.render(
	<>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Provider store={store}>
				<ReactReduxFirebaseProvider {...rrfProps}>
					<SnackbarProvider maxSnack={1} autoHideDuration={3000}>
						<App />
					</SnackbarProvider>
				</ReactReduxFirebaseProvider>
			</Provider>
		</ThemeProvider>
	</>
);
